<template>
  <div :is="layout">
    <v-card>
      <v-card-text class="d-flex justify-lg-space-between">
        <div class="text-h4">
          算法列表
        </div>
        <div>
          <v-btn @click="$router.push({name: 'algorithmCreate'})" depressed>
            <v-icon>mdi-plus</v-icon>
            添加算法
          </v-btn>
        </div>
      </v-card-text>
      <v-simple-table>
        <template>
          <thead>
            <tr>
              <th class="text-center">
                模块名称
              </th>
              <th class="text-center">
                算法语言
              </th>
              <th class="text-center">
                创建时间
              </th>
              <th class="text-center">
                修改时间
              </th>
              <th class="text-center">
                操作
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i of data">
              <td class="text-center">
                {{ i.name }}
              </td>
              <td class="text-center">
                {{ i.mode }}
              </td>
              <td class="text-center">
                {{ getTime((new Date(i.create_time).getTime() / 1000) ,'yyyy-MM-dd hh:mm') }}
              </td>
              <td class="text-center">
                {{ getTime((new Date(i.update_time).getTime() / 1000) ,'yyyy-MM-dd hh:mm') }}
              </td>
              <td class="text-center">
                <v-btn @click="handle_test_algorithm(i)" small text>测试</v-btn>
                <v-btn @click="$router.push({name: 'algorithmEdit', params: {id: i.id}})" small text>修改</v-btn>
                <v-btn @click="download(i)" small text>下载</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-pagination class="float-right" v-model="search.pageindex"
                  :length="page_total">
    </v-pagination>
  </div>
</template>

<script>
import { algorithm_list, algorithm_test } from "@/api/algorithm";

export default {
  name: 'hello',
  data: () => ({
    layout: 'div',
    page_total:0,
    data: [],
    search: {
      pageindex: 1,
      pagesize: 10
    }
  }),
  methods: {
    handle_test_algorithm(item) {
      algorithm_test({id: item.id}).then(res => {
        console.log(res)
      })
    },
    doGet(pageindex = this.search.pageindex) {
      this.search.pageindex = pageindex
      algorithm_list().then(res => {
        this.page_total = Math.ceil(res.count / this.search.pagesize)
        this.data = res.results
      })
    },
    download(item) {
      const urlObject = window.URL || window.webkitURL || window;
      const export_blob = new Blob([item.code])
      const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
      save_link.href = urlObject.createObjectURL(export_blob)
      save_link.download = item.name +  '.py'
      const ev = document.createEvent('MouseEvents')
      ev.initMouseEvent('click', true, false, window, 0, 0,0,0,0,false ,false,false,false,0, null)
      save_link.dispatchEvent(ev)
      urlObject.revokeObjectURL(save_link.href)
    }

  },
  mounted() {
    this.layout = this.$route.meta.use_layout
    this.doGet()
  }
}
</script>

<style lang="stylus" scoped>

</style>