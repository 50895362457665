import { request } from "@/utils";

// 创建算法
export const algorithm_create = data => request.post('/api/algorithm/', data)

// 算法列表
export const algorithm_list = params => request.get('/api/algorithm/', { params })

// 测试算法
export const algorithm_test = data => request.post(`/api/algorithm/${data.id}/test/`, data )

// 查询算法详情
export const algorithm_info = id => request.get(`/api/algorithm/${id}/`)

// 修改算法
export const algorithm_edit = data => request.patch(`/api/algorithm/${data.id}/`, data)